var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "classs": "mb-2"
    }
  }, [_c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openAssetModal($event);
      }
    }
  }, [_vm._v("Pilih Aset")])], 1), _c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('span', {
    staticClass: "d-block mr-1"
  }, [_vm._v("Tanggal Disposal")]), _c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1)], 1), _vm.selectedPenyimpanans.length > 0 ? _c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "items": _vm.selectedPenyimpanans,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.asset ? item.asset.nama : '-') + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('b-form-textarea', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref5) {
        var index = _ref5.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "XCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.selectedPenyimpanans.splice(index, 1);
            }
          }
        })];
      }
    }], null, false, 650563434)
  }) : _c('div', {
    staticClass: "alert alert-warning p-1"
  }, [_vm._v(" Harap pilih aset yang akan dimasukan ke disposal. ")]), _c('br'), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.isValidForm
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)], 1), _c('penyimpanan-modal', {
    on: {
      "submit": _vm.onChooseAsset
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }